import { BUILD_TIMESTAMP } from './timestamp';

const packageJson = require('../../package.json');

export const environment = {
  appName: 'Heiploeg',
  production: false,
  hmr: false,
  name: 'Test',
  baseUrl: 'https://fancy-monaco-8bauy6xwvpbg.vapor-farm-e1.com/api',
  sentryUrl: 'https://5a9a1989d4f847219c6ec2a68c1c6ae6@o4504360297496576.ingest.us.sentry.io/4504831394054144',
  databaseName: 'emendis.complete',
  version: `${packageJson.version}.${BUILD_TIMESTAMP}`,
  redirectAfterLogin: '/',
  url: 'https://verbiest.test.emendis.nl',
};
